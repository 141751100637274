<template>
  <div>
    <Representatives />
  </div>
</template>

<script>
import Representatives from '@/nfmp_components/Representatives.vue'
export default {
  components: {
    Representatives
  }
}
</script>