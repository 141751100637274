<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">Fertilizer Products</h1>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-card-text>
            <h2 class="text-h6 mb-2">
              Select the ones you will import or produce 
            </h2>
            <v-chip-group
              v-model="selections"
              center-active
              mandatory
              multiple
              column
              active-class="green--text text--accent-5 "
            >
              <v-chip
                v-for="item in products"
                :key="item.id"
                :value="item.id"
                filter
                outlined
              >
                {{ item.title }}
              </v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text>
            <v-btn color="green" text @click="postProductsData">
              Submit
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <BackButton />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import BackButton from './assets&products/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout,
    BackButton
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},
    products: {},
    selections: [],

    snackbar: false,
    snackbartext: '',

    valid: false,
    postWarehouseBtnIsActive: false,
    postWarehouseBtnText: 'Send'

  }),

  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getPageData()
  },

  methods: {
    getPageData: function (mode) {
      const url = httpServ.getMemberUrl('/formOptions/products')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        //console.log(result.data.products)
        const selected = []

        this.products = result.data.products
        this.products.forEach((item) => {
          if (item.status > 0) {
            selected.push(item.id)
          }
        })
        this.selections = selected
      })
    },
    getNewData: function () {
      const data = {
        products:this.selections,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
   
    postProductsData: function (mode) {
      const postData = this.getNewData() 

      this.updateProfileBtnIsActive = true
      this.updateProfileBtnText = 'Submiting ...'

      const url = httpServ.getMemberUrl('/account/products')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
        } else {
          //alert(result.message)
          this.snackbartext = result.message + ': ' + result.data.error
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Update failed!'
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
//TODO update to post data