<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">Packages</h1>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add new package
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form v-model="valid">
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="fertilizer_product_id_item"
                              return-object
                              :items="fertilizer_product_id_arr"
                              item-text="title"
                              item-value="id"
                              label="Fertilizer Product*"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="title"
                              label="Package Name*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="fertilizer_unit_rf_item"
                              return-object
                              :items="fertilizer_unit_rf_arr"
                              item-text="title"
                              item-value="id"
                              label="Fertilizer Unit*"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="value"
                              label="Value"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="postWarehouseBtnIsActive"
                        @click="postPackageData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">
                mdi-file-document-edit
              </v-icon>
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <BackButton />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import BackButton from './assets&products/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout,
    BackButton
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},
    equipmentsData: [],

    dialog: false,
    dialogDelete: false,
    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',

    snackbar: false,
    snackbartext: '',

    package_id: 0,
    title: '',
    value: '',
    
    fertilizer_product_id_item: {},
    fertilizer_product_id_arr: [],
    fertilizer_unit_rf_item: {},
    fertilizer_unit_rf_arr: [],

    valid: false,
    postWarehouseBtnIsActive: false,
    postWarehouseBtnText: 'Send',

    headers: [
      {
        text: 'Fertilizer Product',
        align: 'start',
        sortable: false,
        value: 'fertilizer_product'
      },
      { text: 'Unit', value: 'unit' },
      { text: 'Title', value: 'title' },
      { text: 'Value', value: 'value' },
      { text: 'Post Date', value: 'post_date' },
      { text: 'Modify', value: 'actions', sortable: false }
    ],
    tableData: [],

    editedIndex: -1
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Package'
        : 'Modify Existing Package'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
    this.getPageData()
  },

  methods: {
    getTableData: function () {
      const url = httpServ.getMemberUrl('/tables/package')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data.packages
      })
      this.tableLoading = false
    },
    getPageData: function (mode) {
      const url = httpServ.getMemberUrl('/formOptions/packages')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.fertilizer_product_id_arr = result.data.fertilizer_product_id
        this.fertilizer_unit_rf_arr = result.data.fertilizer_unit_rf
      })
    },
    getNewData: function () {
      const data = {
        status: 'new',
        fertilizer_product_id: this.fertilizer_product_id_item.id,
        fertilizer_unit_rf: this.fertilizer_unit_rf_item.id,
        title: this.title,
        value: this.value,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        package_id:this.package_id,
        fertilizer_product_id: this.fertilizer_product_id_item.id,
        fertilizer_unit_rf: this.fertilizer_unit_rf_item.id,
        title: this.title,
        value: this.value,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    postPackageData: function (mode) {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      this.updateProfileBtnIsActive = true
      this.updateProfileBtnText = 'Submiting ...'

      const url = httpServ.getMemberUrl('/account/packages')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close()
          this.getTableData()
        } else {
          //alert(result.message)
          this.snackbartext = result.message + ': ' + result.data.error
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Update failed!'
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      /*this.editedItem = Object.assign({}, item)*/
      this.package_id = item.id
      this.fertilizer_product_id_item.id = item.fertilizer_product_id
      this.title = item.title
      this.value = item.value
      this.fertilizer_unit_rf_item.id = item.fertilizer_unit_rf
      this.mode = 'update'
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.fertilizer_product_id_item.id = 0
        this.title = ''
        this.value = ''
        this.fertilizer_unit_rf_item.id = 0
        this.editedIndex = -1
      })
    }
  }
}
</script>

<style scoped>
</style>