<template>
  <div>
    <Package />
  </div>
</template>

<script>
import Package from '@/nfmp_components/Package.vue'
export default {
  components: {
    Package
  }
}
</script>