<template>
  <Layout is-demo>
    <h1 class="text-h4 ms-0 mb-1">Dashboard</h1>
    <div v-if="currentLOBDetails.memberStatus==='Firstrun'">
      <v-row dense>
        <v-col cols="12" md="3">
          <!-- application card -->
          <v-card v-if="dashboardIsLoadedFR" style="min-height: 132px">
            <div class="pa-1">
              <v-toolbar color="light-green" dark dense flat>
                <v-avatar class="mr-1" rounded color="white" size="34">
                  <v-icon color="green">mdi-domain</v-icon>
                </v-avatar>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-2"
                >
                  Lines of Business
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2">
                <div>
                  <div class="text-caption">
                    <b>Main:</b> {{ dashboardData.main_line_of_business }}
                  </div>
                  <div v-if="dashboardData.second_line_of_business != ''" class="text-caption">
                    <b>Second:</b> {{ dashboardData.second_line_of_business }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-sheet v-else class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <!-- application card -->
          <v-card v-if="dashboardIsLoadedFR" style="min-height: 132px">
            <div class="pa-1">
              <v-toolbar color="light-green" dark dense flat>
                <v-avatar class="mr-1" rounded color="white" size="34">
                  <v-icon color="green">mdi-map-marker</v-icon>
                </v-avatar>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-2"
                >
                  Location
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2">
                <div>
                  <div class="text-caption">
                    <b>State:</b> {{ dashboardData.state_of_residence }}
                  </div>
                  <div class="text-caption">
                    {{ dashboardData.company_address }}
                  </div>
                </div>
              </div>
            </div></v-card>
          <v-sheet v-else class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <!-- application card -->
          <v-card v-if="dashboardIsLoadedFR" style="min-height: 132px">
            <div class="pa-1">
              <v-toolbar color="light-green" dark dense flat>
                <v-avatar class="mr-1" rounded color="white" size="34">
                  <v-icon color="green">mdi-card-account-details</v-icon>
                </v-avatar>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-2"
                >
                  Company Information
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2">
                <div>
                  <div class="text-caption">
                    <b>TIN Number:</b> {{ dashboardData.TIN }}
                  </div>
                  <div class="text-caption">
                    <b>CAC RC:</b> {{ dashboardData.CAC_registration_number }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-sheet v-else class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <!-- application card -->
          <v-card v-if="dashboardIsLoadedFR" style="min-height: 132px">
            <div class="pa-1">
              <v-toolbar color="light-green" dark dense flat>
                <v-avatar class="mr-1" rounded color="white" size="34">
                  <v-icon color="green">mdi-contacts</v-icon>
                </v-avatar>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-2"
                >
                  Contact Information
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2">
                <div>
                  <div class="text-caption">
                    <b>Phone:</b> {{ dashboardData.phone }}
                  </div>
                  <div class="text-caption">
                    <b>Email:</b> {{ dashboardData.email }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-sheet v-else class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <div>
        <v-row v-if="currentLOBDetails.LOBId === 1">
          <v-col sm="12" md="7" style="padding-right: 0px !important">
            <v-card class="d-card-height">
              <v-card-text>
                <h3 class="card-header-text">
                  Payments for {{ currentLOBDetails.LOBname }}
                </h3>
                <div class="text--primary">
                  <v-data-table
                    :headers="distributorsheaders"
                    :items="fees"
                    item-key="name"
                    class="elevation-1"
                    :hide-default-footer="true"
                  ></v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" md="5">
            <v-card class="d-card-height">
              <v-card-text>
                <h3 class="card-header-text">
                  Registration Completion Guidelines
                </h3>
                <div class="text--primary reg-guide-text">
                  <ol>
                    <li>Update the the Company Profile page</li>
                    <li>
                      Update the Assets & Products page. Make sure this is done in
                      this order:
                    </li>
                    <ul>
                      <li>Update the Warehouses Summary</li>
                      <li>Update the Representative Summary</li>
                      <li>Update the Fertilizer Products Summary</li>
                      <li>Update the Fertilizer Packages Summary</li>
                    </ul>
                    <li>Make the Processing fee payment</li>
                    <li>Wait for the Inspection notification</li>
                    <li>Finally, make the Registration fee payment</li>
                  </ol>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col sm="12" md="7" style="padding-right: 0px !important">
            <v-card class="d-card-height">
              <v-card-text>
                <h3 class="card-header-text">
                  Payments for
                  {{ currentLOBDetails.LOBname }}
                </h3>
                <div class="text--primary">
                  <v-data-table
                    :headers="othersheaders"
                    :items="fees"
                    item-key="name"
                    class="elevation-1"
                    :hide-default-footer="true"
                  ></v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" md="5">
            <v-card class="d-card-height">
              <v-card-text>
                <h3 class="card-header-text">
                  Registration Completion Guidelines
                </h3>
                <div class="text--primary reg-guide-text">
                  <ol>
                    <li>Update the the Company Profile page</li>
                    <li>
                      Update the Assets & Products page. Make sure this is done in
                      this order:
                    </li>
                    <ul>
                      <li>Update the Warehouses Summary</li>
                      <li>Update the Representative Summary</li>
                      <li>Update the Fertilizer Products Summary</li>
                      <li>Update the Fertilizer Packages Summary</li>
                    </ul>
                    <li>Make the Processing fee payment</li>
                    <li>Wait for the Inspection notification</li>
                    <li>Finally, make the Registration fee payment</li>
                  </ol>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="6">
          <v-card v-if="dashboardIsLoadedFM" style="min-height: 132px" class="rounded-3" >
            <div class="pa-0">
              <v-toolbar color="light-green" dark dense flat>
                <v-icon color="white" x-large>mdi-certificate</v-icon>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-1 px-2"
                >
                  Certificate Info
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2 h6">
                <div>
                  <div class="">
                    <b>Certificate Number:</b> {{ dashboardData.certificateNumber }}
                  </div>
                  <div class="">
                    <b>Certificate Expiry Date:</b> {{ dashboardData.certificateExpiryDate }}
                  </div>
                  <div class="">
                    <b>Certificate Status:</b> {{ dashboardData.certificateStatus }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-sheet v-else class="pa-3" >
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-card v-if="dashboardIsLoadedFM" style="min-height: 132px" class="rounded-3" >
            <div class="pa-0">
              <v-toolbar color="light-green" dark dense flat>
                <v-icon color="white" x-large>mdi-bank</v-icon>
                <v-toolbar-title
                  color="white"
                  class="font-weight-bold text-body-1 px-2"
                >
                  Account Info
                </v-toolbar-title>
              </v-toolbar>
              <div class="d-flex align-center pa-2 h6">
                <div>
                  <div class="">
                    <b>Membership Id:</b> {{ dashboardData.membership_id }}
                  </div>
                  <div class="">
                    <b>Tax Identification Number:</b> {{ dashboardData.TIN }}
                  </div>
                  <div class="">
                    <b>RC Number:</b> {{ dashboardData.CAC_registration_number }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-sheet v-else class="pa-3" >
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar,list-item"
              max-height="132px"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="5" sm="12">
          <v-card v-if="dashboardIsLoadedFM" style="min-height: 132px" class="rounded-3" >
            <Plotly :data="plotlyGraph1.data" :layout="plotlyGraph1.layout" :display-mode-bar="false"></Plotly>
            <div>Showing fertilizer product summary(pie)</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" sm="12">
          <v-card v-if="dashboardIsLoadedFM" style="min-height: 132px" class="rounded-3" >
            <Plotly :data="plotlyGraph2.data" :layout="plotlyGraph2.layout" :display-mode-bar="false"></Plotly>
            <div>Monthly production and sales report</div>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" class="text--grey">
                  <div class="h3 text--black">1000</div>
                  Total Production/Import for the peroid under review
                </v-col>
                <v-col cols="12" md="12"></v-col>
              </v-row>            </v-card-text>
          </v-card>
          
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
//import { Plotly } from 'vue-plotly'

export default {
  components: {
    Layout
  },
  data() {
    return {
      alterLOBFlag: false,
      someDate:'2023-05-03 00:00:00',
      memberData: {},
      dashboardData: {
        main_line_of_business: 'loading...',
        second_line_of_business: '',
        state_of_residence: 'loading...',
        company_address: 'loading...',
        TIN: 'loading...',
        CAC_registration_number: 'loading...',
        phone: 'loading...',
        email: 'loading...',
        certificateNumber:'loading...',
        certificateExpiryDate:'loading...',
        certificateStatus:'loading...',
        membership_id:'loading...'
      },
      dashboardIsLoadedFR: false,
      dashboardIsLoadedFM: true,
      tableIsLoaded: false,
      prePostData: {},
      currentLOBDetails: {},
      showAlert: true,
      othersheaders: [
        {
          text: 'Payment Type',
          align: 'start',
          sortable: true,
          value: 'payment_type'
        },
        { text: 'Fees', value: 'fees' }
      ],
      distributorsheaders: [
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Fees (Cat. A)', value: 'fees_category_a' },
        { text: 'Fees (Cat. B)', value: 'fees_category_b' },
        { text: 'Fees (Cat. C)', value: 'fees_category_c' }
      ],
      fees: [],
      currentLOBname: '',
      plotlyGraph1:{
        data:[{
          x: [1,2,3,4],
          y: [10,15,13,17],
          type:'pie'
        }],
        layout:{
          title: 'Current quater summary',
          showlegend: true
        }
      },
      plotlyGraph2:{
        data:[{
          x: [1,2,3,4],
          y: [10,15,13,17],
          type:'bar'
        }],
        layout:{
          title: 'Last six months summary'
        }
      }
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    //console.log(this.memberData)
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      //jwtToken: this.memberData.jwt,
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    if (this.currentLOBDetails.memberStatus === 'Firstrun') {
      this.getDisplayData4FR()
      this.getTableData(this.memberData.displayLOB)
    } else {
      console.log('get FM')
    }
    
  },
  mounted() {
    this.$root.$on('get-dashbardTable-data', () => {
      this.getTableData()
    })
  },
  methods: {
    getDisplayData4FR: function () {
      const url = httpServ.getMemberUrl('/account/firstrunDashboard')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        const d = result.data

        this.dashboardData.main_line_of_business =
          this.memberData.lobs['main_line_of_business'].line_of_business_name
        if (
          this.memberData.lobs['second_line_of_business']
            .line_of_business_name === 'NULL'
        ) {
          this.dashboardData.second_line_of_business = ''
        } else {
          this.dashboardData.second_line_of_business = 
          this.memberData.lobs['second_line_of_business'].line_of_business_name
        }
        this.dashboardData.state_of_residence = d.location.state_of_residence
        this.dashboardData.company_address = d.location.company_address
        this.dashboardData.TIN = d.company.TIN
        this.dashboardData.CAC_registration_number =
          d.company.CAC_registration_number
        this.dashboardData.phone = d.contact.phone
        this.dashboardData.email = d.contact.phone
        this.dashboardIsLoadedFR = true
      })
    },
    getDisplayData4FM: function () {
      const url = httpServ.getMemberUrl('/account/firstrunDashboard')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        const d = result.data

        this.dashboardData.main_line_of_business =
          this.memberData.lobs['main_line_of_business'].line_of_business_name
        if (
          this.memberData.lobs['second_line_of_business']
            .line_of_business_name === 'NULL'
        ) {
          this.dashboardData.second_line_of_business = ''
        } else {
          this.dashboardData.second_line_of_business = 
          this.memberData.lobs['second_line_of_business'].line_of_business_name
        }
        this.dashboardData.state_of_residence = d.location.state_of_residence
        this.dashboardData.company_address = d.location.company_address
        this.dashboardData.TIN = d.company.TIN
        this.dashboardData.CAC_registration_number =
          d.company.CAC_registration_number
        this.dashboardData.phone = d.contact.phone
        this.dashboardData.email = d.contact.phone
        this.dashboardIsLoadedFR = true
      })
    },
    getTableData: function (currentLOB) {
      //console.log('getTableData with ',currentLOB)
      this.currentLOBDetails.memberStatus =
      this.memberData.lobs[currentLOB].membership_status
      this.currentLOBDetails.LOBId =
      this.memberData.lobs[currentLOB].line_of_business_id
      this.currentLOBDetails.LOBname =
      this.memberData.lobs[currentLOB].line_of_business_name
      this.prePostData.LOB_id = this.memberData.lobs[currentLOB].line_of_business_id

      const url = httpServ.getMemberUrl('/tables/firstrunDashboardFee')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.fees = result.data.fees
        this.tableIsLoaded
      })
    }
  }
}
</script>

<style scoped>
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
  color: green;
}
.v-alert {
  color: green !important;
}
.card-header-text {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height {
  min-height: 500px !important;
}

::v-deep .v-data-table td {
  height: 20px !important;
  font-size: 14px !important;
}

::v-deep .v-data-table th {
  font-size: 12px !important;
}

.reg-guide-text {
  font-size: 13px !important;
}
</style>
