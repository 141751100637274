<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">
        Business Profile
        <v-divider class="my-2"></v-divider>
      </h1>
    </div>
    <v-form ref="form" v-model="valid" class="mt-2">
      <v-row>
        <v-col cols="12" sm="12" md="6" class="py-0">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_business_commencement"
                label="Date Of Business Commencement*"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_of_business_commencement"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="6" class="py-0">
          <v-text-field
            v-model="current_annual_operational_capacity"
            label="Current Annual Operational Capacity(MT)*"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="currentLOBDetails.LOBId === 1">
        <v-col md="6" class="py-0">
          <v-select
            v-model="production_category_rf"
            return-object
            :items="production_category"
            item-text="title"
            item-value="id"
            label="Sales Category"
            :selected="production_category_rf"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" class="py-0">
          <v-select
            v-model="association_registered_with_rf"
            return-object
            :items="association_registered_with"
            item-text="title"
            item-value="id"
            label="Association Registered With"
            :selected="association_registered_with_rf.id"
            required
          ></v-select>
        </v-col>
        <v-col v-if="associationCategory === 'new'" md="3" class="py-0">
          <v-text-field
            v-model="alt_association"
            label="Association Name"
          ></v-text-field>
        </v-col>
        <v-col v-if="associationCategory === 'existing'" md="4" class="py-0">
          <v-text-field
            v-model="association_registration_number"
            label="Association Registration Number"
          ></v-text-field>
        </v-col>
        <v-col v-if="associationCategory === 'existing'" md="4" class="py-0">
          <v-menu
            v-model="menu2date"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_registration_with_association"
                label="Date Of Registration With Association"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_of_registration_with_association"
              @input="menu2date = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="associationCategory === 'new'" md="3" class="py-0">
          <v-text-field
            v-model="association_registration_number2"
            label="Association Registration Number"
          ></v-text-field>
        </v-col>
        <v-col v-if="associationCategory === 'new'" md="3" class="py-0">
          <v-menu
            v-model="menu3date"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_registration_with_association2"
                label="Date Of Registration With Association"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_of_registration_with_association2"
              @input="menu2date = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="currentLOBDetails.LOBId > 1" md="12" class="pb-0">
          <h4 class="font-weight-bold">Fertilizer products</h4>
          <span
            class="caption"
          >Select the ones you will import or produce </span>
        </v-col>
        <v-col md="12" class="pb-0">
          <v-card
            class="d-flex align-content-start flex-wrap"
            color="grey lighten-2"
            flat
            tile
            min-height="50"
          >
            <div v-for="item in products" :key="item.id" class="ps-3 py-0 ms-2">
              <v-checkbox
                v-model="selections"
                class="caption"
                :label="item.title"
                :value="item.id"
                hide-details
              ></v-checkbox>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-layout justify-center>
        <div class="text-center ma-5">
          <v-btn
            block
            class="success"
            medium
            :disabled="updateProfileBtnIsActive"
            @click="updateProfile"
          >
            {{ UpdateProfileBtnText }}
          </v-btn>
        </div>
      </v-layout>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },

  data() {
    return {
      memberData: {},
      products: [],
      selections: [],
      UpdateProfileBtnText: 'Update Profile',
      updateProfileBtnIsActive: false,

      snackbar: false,
      snackbartext: '',

      menu2: false,
      menu2date: false,
      menu3date: false,

      association_registered_with: [],
      production_category: [],
      currentLOBDetails: {},
      valid: false,
      association_registered_with_rf: {},
      current_annual_operational_capacity: '',
      date_of_business_commencement: '',
      production_category_rf: {},
      date_of_registration_with_association: '',
      alt_association: '',
      association_registration_number: '',
      association_registration_number2: '',
      date_of_registration_with_association2: '',
      associationCategory: 'none'
    }
  },
  watch: {
    association_registered_with_rf: {
      handler(newValue) {
        if (newValue.id < 99) {
          this.associationCategory = 'existing'
        } else if (newValue.id === 99) {
          this.associationCategory = 'new'
        } else {
          this.associationCategory = 'none'
        }
        //console.log(newValue.id + ':' + this.associationCategory)
      },
      immediate: true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    const url = httpServ.getMemberUrl('/formOptions/businessProfile')
    const resp = httpServ.postFetchWithToken(
      url,
      this.prePostData,
      this.memberData.jwt
    )

    resp.then((result) => {
      const d = result.data

      this.products = d.member_data_products
      this.association_registered_with = d.association_registered_with_rf
      this.production_category = d.production_category_rf
      //console.log(d.member_data[0])
      if (d.member_data[0] !== undefined) {
        this.date_of_business_commencement =
          d.member_data[0].date_of_business_commencement
        this.production_category_rf = d.member_data[0].production_category_rf
        this.current_annual_operational_capacity =
          d.member_data[0].current_annual_operational_capacity
        this.association_registered_with_rf.id =
          d.member_data[0].association_registered_with_rf
        this.alt_association = d.member_data[0].alt_association
        this.association_registration_number =
          d.member_data[0].association_registration_number
        this.date_of_registration_with_association =
          d.member_data[0].date_of_registration_with_association
        this.association_registration_number2 =
          d.member_data[0].association_registration_number2
        this.date_of_registration_with_association2 =
          d.member_data[0].date_of_registration_with_association2
        const selected = []

        if (this.products !== undefined) {
          this.products.forEach((item) => {
            if (item.status > 0) {
              selected.push(item.id)
            }
          })
          this.selections = selected
        }
        
      }
    })
  },
  mounted() {},
  methods: {
    updateProfile: function () {
      this.updateProfileBtnIsActive = true
      this.updateProfileBtnText = 'Submiting ...'
      const updateData = {
        date_of_business_commencement: this.date_of_business_commencement,
        current_annual_operational_capacity:
          this.current_annual_operational_capacity,
        association_registered_with_rf: this.association_registered_with_rf.id,
        association_registration_number: this.association_registration_number,
        date_of_registration_with_association:
          this.date_of_registration_with_association,
        alt_association: this.alt_association,
        association_registration_number2: this.association_registration_number2,
        date_of_registration_with_association2:
          this.date_of_registration_with_association2,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id,
        membership_id: this.memberData.membership_id
      }

      if (
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id ===
        1
      ) {
        if (this.production_category_rf && typeof this.production_category_rf === 'object' 
        && !Array.isArray(this.production_category_rf)) {
          updateData['production_category_rf'] = this.production_category_rf.id
        } else {
          //console.log('prod' + this.production_category_rf)
          updateData['production_category_rf'] = this.production_category_rf
        }
     
      } else {
        updateData.products = this.selections
      }

      //console.log(updateData)
      const url = httpServ.getMemberUrl('/account/businessProfile')
      const resp = httpServ.postFetchWithToken(
        url,
        updateData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.updateProfileBtnText = 'Update Profile'
        this.updateProfileBtnIsActive = false
        if (result.status === 200) {
          this.snackbartext = 'Update successful!'
          this.snackbar = true
        } else {
          this.snackbartext = result.message + ': ' + result.data.error
          this.snackbar = true
        }
      })
    }
    
  }
}
</script>

<style scoped>
</style>
