<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }" >
      <v-badge
        bordered
        :content="notices.length"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon v-on="on">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <!-- dropdown card -->
    <v-card>
      <v-list three-line dense max-width="400">
        <v-subheader class="pa-2 font-weight-bold">Notifications</v-subheader>
        <div v-for="(item, index) in notices" :key="index">
          <v-divider v-if="index > 0 && index < items.length" inset></v-divider>

          <v-list-item @click="">
            <v-list-item-avatar size="32" :color="item.color">
              <v-icon dark small>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle class="caption" v-text="item.body"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-action-text> {{ item.postDate | moment("from", "now", true) }} </v-list-item-action-text>
              <v-chip small>{{ item.LOB | AbbreviateLOB }}</v-chip>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>

      <div class="text-center py-2">
        <v-btn small>See all</v-btn>
        <v-btn small>Clear</v-btn>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-menu>
</template>

<script>
import httpServ from '../../services/HTTPService'
import tts from '../../services/TTSService'
export default {
  filters: {
    AbbreviateLOB: (value) => {
      if (value === 'Fertilizer Importer') {
        return 'IMPT'
      } else if (value === 'Fertilizer Manufacturer') {
        return 'MANF'
      } else if (value === 'Fertilizer Blender') {
        return 'BLND'
      } else {
        return 'DIST'
      }
    }
  },
  data() {
    return {
      notices:[],
      prePostData:{},
      memberData:{},
      items: [],
      snackbar: false,
      snackbartext:''
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getNotices()
  },
  methods: {
    getNotices: function () {
      //console.log('without token')
      const url = httpServ.getNotificationUrl('/webNotification/summarizedList')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        
        this.notices = result.data
        let speeches = 'Hi, you have the following new notices!'
        let count = 0

        this.notices.forEach((item) => {
          ++count
          speeches += 'Notice ' + count + '. ' + item.body + '! '
        })

        if (this.memberData.noticeSummarySpeech) {
          if (count > 0) {
            this.snackbartext = 'Stop voice notices'
            this.snackbar = true
            tts.speak( speeches)
          }
          const data = httpServ.getSessionJson('memberData')

          data.noticeSummarySpeech = false
          
          httpServ.setSessionJson('memberData',data)
        }
      })
    },
    refreshNotices: function() {
    }
  }
}
</script>
