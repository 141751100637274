<template>
  <div>
    <Products />
  </div>
</template>

<script>
import Products from '@/nfmp_components/Products.vue'
export default {
  components: {
    Products
  }
}
</script>