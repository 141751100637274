<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">Assets & Product</h1>
    </div>
    <v-row>
      <v-col md="6" sm="12">
        <v-card>
          <v-toolbar
            color="green"
            dark
            dense
            flat
          >
            <v-toolbar-title class="text-body-1">
              Warehouses/Stores/Storage Facilities Summary
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="70" color="light-green lighten-2">
                  <div class="text-h2 white--text">{{ pageData.warehouse_count }}</div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> warehouse(s) updated so far!</v-list-item-title>
                  <v-list-item-subtitle>To make a new entry click the button below</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="green" outlined @click="showWarehousesPage">
              Update warehouses info
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card>
          <v-toolbar
            color="green"
            dark
            dense
            flat
          >
            <v-toolbar-title class="text-body-1">
              Representative Summary
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="70" color="light-green lighten-2">
                  <div class="text-h2 white--text">{{ pageData.representative_count }}</div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> representative(s) updated so far!</v-list-item-title>
                  <v-list-item-subtitle>To update this, click the button below</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="green" outlined @click="showRepresentativesPage">
              Update representatives info
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="currentLOBDetails.LOBId>1" md="6" sm="12">
        <v-card>
          <v-toolbar
            color="green"
            dark
            dense
            flat
          >
            <v-toolbar-title class="text-body-1">
              Fertilizer Products Summary
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="70" color="light-green lighten-2">
                  <div class="text-h2 white--text">{{ pageData.products_count }}</div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> product(s) updated so far!</v-list-item-title>
                  <v-list-item-subtitle>To update this click the button below</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="green" outlined @click="showProductsPage">
              Update fertilizer products info
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="currentLOBDetails.LOBId>1" md="6" sm="12">
        <v-card>
          <v-toolbar
            color="green"
            dark
            dense
            flat
          >
            <v-toolbar-title class="text-body-1">
              Fertilizer Package Summary
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="70" color="light-green lighten-2">
                  <div class="text-h2 white--text">{{ pageData.packages_count }}</div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> package(s) updated so far!</v-list-item-title>
                  <v-list-item-subtitle>To update this click the button below</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="green" outlined @click="showPackagesPage">
              Update fertilizer packages info
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{
        warehouse_count: 0,
        representative_count: 0,
        products_count: 0,
        packages_count: 0 }
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    const url = httpServ.getMemberUrl('/formOptions/assetsProducts')
    const resp = httpServ.postFetchWithToken(
      url,
      this.prePostData,
      this.memberData.jwt
    )

    resp.then((result) => {
      const d = result.data

      this.pageData = d
    })
  },
  mounted() {},
  methods: {
    showWarehousesPage: function() {
      this.$router.push({ name: 'warehouses' })
    },
    showRepresentativesPage: function() {
      this.$router.push({ name: 'representatives' })
    },
    showProductsPage: function() {
      this.$router.push({ name: 'products' })
    },
    showPackagesPage: function() {
      this.$router.push({ name: 'packages' })
    }
  }
}
</script>

<style scoped>
</style>
